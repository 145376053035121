<template>
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3181 4.34848C16.9329 3.7143 15.4706 3.2644 13.9684 3.01025C13.7629 3.37771 13.5769 3.75577 13.4113 4.14285C11.8112 3.90175 10.1841 3.90175 8.58402 4.14285C8.41829 3.75581 8.23231 3.37775 8.02684 3.01025C6.52376 3.26654 5.06046 3.71752 3.67387 4.3518C0.921138 8.42452 0.174915 12.3961 0.548027 16.3113C2.1601 17.5023 3.96446 18.4082 5.88269 18.9894C6.31462 18.4085 6.69682 17.7922 7.02524 17.147C6.40146 16.9141 5.79939 16.6266 5.22602 16.288C5.37692 16.1786 5.52451 16.0658 5.66712 15.9564C7.3355 16.741 9.15646 17.1478 11.0001 17.1478C12.8438 17.1478 14.6647 16.741 16.3331 15.9564C16.4774 16.0741 16.625 16.1869 16.7742 16.288C16.1997 16.6272 15.5966 16.9152 14.9717 17.1487C15.2997 17.7935 15.6819 18.4093 16.1142 18.9894C18.0341 18.4105 19.8399 17.5051 21.4522 16.3129C21.89 11.7726 20.7043 7.83749 18.3181 4.34848ZM7.49785 13.9035C6.45811 13.9035 5.59913 12.9599 5.59913 11.7991C5.59913 10.6383 6.42827 9.68646 7.49453 9.68646C8.5608 9.68646 9.41316 10.6383 9.39492 11.7991C9.37667 12.9599 8.55749 13.9035 7.49785 13.9035ZM14.5024 13.9035C13.461 13.9035 12.6053 12.9599 12.6053 11.7991C12.6053 10.6383 13.4345 9.68646 14.5024 9.68646C15.5703 9.68646 16.416 10.6383 16.3978 11.7991C16.3796 12.9599 15.562 13.9035 14.5024 13.9035Z"
    />
  </svg>
</template>
